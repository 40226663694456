#admin-login-page {
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
}

#admin-login-page .login-form {
  max-width: 300px;
}
#admin-login-page .login-form-button {
  width: 100%;
}
